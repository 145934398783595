
.MuiToggleButtonGroup-root *{
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0);
}

.MuiToggleButton-root.MuiToggleButtonGroup-grouped *{
    color: whitesmoke;
    background-color: rgba(33, 33, 33, 0.02);
} 
.MuiToggleButton-root.MuiToggleButtonGroup-grouped:hover{
    background-color: rgba(50, 50, 50, 0.2);
} 
.MuiToggleButton-root.MuiToggleButtonGroup-grouped.Mui-selected:hover{
    background-color: rgb(0, 0, 0);
    color: white;
} 
.MuiToggleButton-root.MuiToggleButtonGroup-grouped.Mui-selected{
    color: white;
    background-color: rgb(0, 0, 0);
} 
.MuiToggleButtonGroup-grouped:not(:first-of-type){
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    border-top-left-radius: 0%;
    border-bottom-left-radius: 0%;
}

.MuiToggleButtonGroup-grouped:not(:last-of-type){
    border-top-right-radius: 0%;
    border-bottom-right-radius: 0%;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 100%;
}

.MuiDivider-root.MuiDivider-fullWidth{
    border-color: rgba(228, 228, 228, 0.836);
    border-bottom-width: medium;
    margin: 10px 0;
}
.uvBar{
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-bottom: -40px;
}
.uvBar2{
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
}