.background{
    background-image: url('../src/weather-images/weather-image-mobile.jpg');
    background-repeat: no-repeat;
    /* background-size: auto; */
    background-size: 100vw 100vh;
    width: 100vw;
    height: 100vh;
}

.main_component{
    position: absolute;
    /* background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); */
    width: 100vw;
    height: 100vh;
    border-radius: 4vh;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (min-width: 900px) {
    .main_component{
        position: absolute;
        /* background-color: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px); */
        width: 90vw;
        height: 90vh;
        margin: 5vh 5vw;
        border-radius: 4vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .sidebar{
        position: absolute;
        border-radius: 4vh 0vh 0vh 4vh;
    }
    .detail_component{
        position: absolute;
        border-radius: 0vh 4vh 4vh 0vh;
    }
}

.sidebar{
    padding: 1vw;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    width: inherit;
    height: 100%;
    border-radius: 0vh;
}

.detail_component{
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    width: inherit;
    height: 100%;
    border-radius: 0vh;
}

.search_results{
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 5vh;
    list-style: none;
    margin: 0 auto;
    width: 90%;
    padding: 0;
    color: azure;
    background: rgba(0, 0, 0, 0.75);
}

.search_results li{
    padding: 1vh 1vw;
    font-size: 14px;
    cursor: pointer;
    border: 1px;
    border-color: rgba(255, 255, 255, 0.234);
    border-bottom-style: solid;
}

.search_results li:last-child{
    border-style: none;
}

.searchbar{
    border-radius: 5vh;
    height: 5vh;
    border: none;
    padding: 0 1vw;
    background-color: rgba(0,0,0, 0.2);
    /* -webkit-backdrop-filter: blur(10px); */
    /* -webkit-back */
    /* backdrop-filter: blur(10px); */
    margin: 1vw;
}
.searchbarinput{
    /* border-radius: 20px; */
    width: 100%;
    height: 50px;
    border: none;
    font-size: 20px;
    padding: 0 10px;
    color: rgb(201, 201, 201);
    background-color: rgba(0,0,0,0);
}
.searchbarinput:focus{
    outline: none;
}
.location_button{
    background-color: rgba(0,0,0,0);
    border: none;
    cursor: pointer;
}

.menubar{
    height: max-content;
    background-color: transparent;
    display: flex;
    padding: 2vw;
}

.menutab{
    width: fit-content;
    width: -moz-fit-content;
    height: 5vh;
    background-color: rgba(35, 35, 35, 0.588);
    border: none;
    padding: 0 1%;
    border-radius: 25vw;
    font-size: 2vh;
    font-weight: 500;
    margin: 0 0 0 1vw;
    color: white;
    cursor: pointer;
}
.temperature_button{
    display: block;
    background-color: rgba(255 ,255 ,255 ,0.5);
    border: none;
    /* padding: 0 1%;
    border-radius: 25vw; */
    font-size: 2vh;
    font-weight: 500;
    margin: 0 1vw 0 0;
    color: black;
    width: 8%;
    /* height: 100%; */
    /* cursor: pointer; */
}

.spacer_div{
    width: 100rem;
}
.spacer_div_single{
    width: 1rem;
}
.roundedtoggleright{
    border-top-right-radius: 100%;
    border-bottom-right-radius: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.weekData{
    width: 100%;
    /* height: 20vh; */
    padding: 2% 0;
}

.weekInfoBlock{
    width: 12%;
    /* height: 5vh; */
    background-color: rgba(255 ,255 ,255 ,0.5);
    border: none;
    padding: 1% 1%;
    border-radius: 2vw;
    font-size: 2vh;
    font-weight: 500;
    margin: 0 0.5%;
    color: black;
    display: inline-block;
}

.weekWeatherIcon{
    font-size: 200%;
    color: rgba(28, 28, 28, 0.666);
}
.currentWeatherIcon{
    margin: 8%;
    font-size:140px;
    color: rgba(28, 28, 28, 0.666);
}
.currentWeather{
    margin: 0 10%;
    color: rgba(248, 250, 255, 0.742);
}
.currentWeatherTemp{
    font-size: 70px;
}

.dayData{
    margin: 0 auto;
    width: 90%;
    height: min-content;
    padding: 2% 0;
    /* overflow-x: scroll;
    overflow-y: hidden; */
}

.dayInfoBlock{
    width: 11%;
    height: min-content;
    background-color: rgba(255 ,255 ,255 ,0.5);
    border: none;
    padding: 1% 1%;
    border-radius: 0.5vw;
    font-size: 2vh;
    font-weight: 500;
    margin: 0 0.5%;
    color: black;
    display: inline-block;
}
.dayInfoBlockMobile{
    width: 13%;
    height: min-content;
    background-color: rgba(255 ,255 ,255 ,0.5);
    border: none;
    padding: 1% 1%;
    border-radius: 0.5vw;
    font-size: 2vh;
    font-weight: 500;
    margin: 0 0.5%;
    color: black;
    display: inline-block;
}
.vertical_spacer{
    height: 1rem;
}
.dayWeatherIcon{
    margin: 8%;
    font-size: 180%;
    color: rgba(28, 28, 28, 0.666);
}
.sideSmallWeatherIcon{
    margin: 8%;
    font-size: 180%;
    color: rgba(255, 255, 255, 0.666);
}
.mainSmallWeatherIcon{
    margin: 8%;
    font-size: 280%;
    color: rgba(28, 28, 28, 0.666);
}
.highlights_component{
    background-color: blue;
}

.indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    font-size: 1.8em;
    font-weight: normal;
    color: #555;
}